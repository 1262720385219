/* Login background style */
.login-container {
    height: 100vh;
    max-width: 100vw !important;
}

/* Login form box style */
#login-form {
    padding: 1rem 2rem;
    margin: 0;
    width: 350px;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    box-shadow: 2px 2px 8px black;
    border-radius: 10px;
}

/* Login input field style */
.MuiFormControl-root.login-input {
    width: 100%;
    margin-bottom: 1.2rem;
}

/* Login button style */
#login-btn {
    display: block;
    width: 100%;
    margin: 2rem 0 2rem 0;
    font-weight: bold;
}

/* Login logo style */
.login-logo {
    width: 100%;
    height: auto;
    margin: 10px auto 15px;
}

@media (max-width: 479px) {
    #login-form {
        width: 60vw;
        padding: 0.8rem 1.5rem 0;
    }
    #login-btn {
        margin-top: 1rem;
    }
    .login-input .MuiInput-root .MuiInputAdornment-root .MuiSvgIcon-root {
        font-size: 1.3rem;
    }
}

@media (min-width: 480px) and (max-width: 767px) {
    #login-form {
        width: 55vw;
    }
}