.manual-pagination {
    display: flex;
    width: min-content;
    margin-left: auto;
    padding-top: 6px;
}

.manual-pagination svg,
.manual-pagination span {
    margin-top: 3px;
}

.add-btn .MuiButton-label {
    font-weight: bold;
}

.manual-pagination .MuiFormControl-root {
    margin-left: 10px;
    margin-right: 10px;
}
.manual-pagination .MuiOutlinedInput-input {
    width: 40px;
    padding: 5px;
    text-align: center;
}
.manual-pagination .last-idx {
    margin-right: 10px;
}

@media (max-width: 767px) {
    .manual-pagination {
        margin-right: auto;
        padding-top: 0;
    }
}