.material-table .MuiToolbar-root {
    padding: 0;
}
.material-table .MuiToolbar-root .MuiTypography-h6 {
    padding-left: 15px;
    padding: 15px;
    overflow: unset !important;
    white-space: initial !important;
}
.material-table .MuiToolbar-root [class*="MTableToolbar-searchField"],
.material-table .MuiToolbar-root [class*="jss"].MuiTextField-root {
    padding-right: 15px;
}
.material-table .MuiTable-root .MuiTableFooter-root {
    /* box-shadow: 0px -1px 3px gray; */
    border-top: solid 3px lightgray;
}

.MuiTable-root thead tr th {
    font-weight: bold;
    text-align: left !important;
}
.MuiTableCell-paddingCheckbox:last-child {
    padding-left: 15px !important;
    padding-right: 15px !important;
}

/* Always show sort arrow */
/* .MuiTable-root thead tr th .MuiTableSortLabel-icon {
    opacity: 1;
} */

.add-btn-table.MuiButton-root {
    font-weight: bold;
}
.table-footer.MuiGrid-root {
    margin-top: -45px;
    padding-left: 15px;
}
[class*="MTableToolbar-searchField"] .MuiInput-underline::before,
[class*="jss"].MuiTextField-root .MuiInput-underline::before,
[class*="MTableToolbar-searchField"] .MuiInput-underline::after,
[class*="jss"].MuiTextField-root .MuiInput-underline::after {
    border: none;
}
/* .MuiTablePagination-input, .MuiTableCell-head, .MuiTableCell-root {
    font-family: 'Verdana' !important;
} */
.MuiTableBody-root .MuiTableRow-root .MuiTableCell-body,
.MuiTableBody-root .MuiTableRow-root .MuiTableCell-body p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* Style for all classes that contain "MTableToolbar-searchField" */
[class*="MTableToolbar-searchField"] .MuiInput-underline,
[class*="jss"].MuiTextField-root .MuiInputBase-root.MuiInput-root.MuiInput-underline  {
    padding-left: 10px;
    border-radius: 3px;
    margin-right: 10px;
}
.Component-horizontalScrollContainer-12::-webkit-scrollbar {
    /* [CHROME] width of the entire scrollbar */
    width: 7px;
}
.Component-horizontalScrollContainer-12::-webkit-scrollbar-thumb {
    border-radius: 20px;            /* [CHROME] roundness of the scroll thumb */
}

/* Style for text on table footer */
.table-footer.MuiGrid-root .total-income-text {
    position: absolute;
    padding: 7px;
}

/* Status Style */
.status-done,
.status-validated,
.status-approved,
.status-declined,
.status-pending {
    display: block;
    margin: 0;
    padding: 5px 10px;
    text-align: center;
    border-radius: 20px;
    font-size: 0.7rem;
    font-weight: bold;
}

@media (max-width: 479px) {
    .table-footer.MuiGrid-root {
        margin-top: 1rem;
        padding-left: 0;
    }
}

@media (min-width: 480px) and (max-width: 767px) {
    .table-footer.MuiGrid-root {
        margin-top: 1rem;
        padding-left: 0;
    }
}

@media (max-width: 767px) {
    .material-table .MuiToolbar-root {
        display: block;
        padding-bottom: 1px;
    }
    .material-table .MuiToolbar-root .MuiTypography-h6 {
        text-align: center;
    }
    .material-table .MuiToolbar-root [class*="MTableToolbar-searchField"] {
        width: 90%;
        margin-bottom: 20px;
        padding: 0;
        margin-left: auto;
        margin-right: auto;
        display: block;
    }
    [class*="MTableToolbar-searchField"] .MuiInput-underline {
        width: 100%;
        margin-right: 0;
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .table-footer.MuiGrid-root {
        margin-top: 1rem;
        padding-left: 0;
    }
}