/* Table Style */
.manual-table {
    width: 100%;
}

/* Header Style */
.manual-table th  {
    padding: 10px;
}

/* Body Style */
.manual-table .odd-cell td,
.manual-table .even-cell td {
    padding: 10px;
    line-height: 1.5rem;
    border: none;
}
.manual-table .center-cell {
    text-align: center;
}
.manual-table .right-cell {
    text-align: right;
}

/* Stock Opname Input Field Style */
.manual-table .input-stock .MuiInputBase-input {
    text-align: center;
    max-width: 100px;
}
.manual-table .input-stock-reason .MuiInputBase-input {
    text-align: center;
}

/* Transaction Table Footer Style */
.manual-table.transaction .footer {
    padding: 5px 10px;
    text-align: right;
}

/* Retur Input Field Checkbox Style */
.checkbox-retur .MuiFormControlLabel-root {
    margin: 0;
}
.checkbox-retur .MuiCheckbox-root {
    margin-left: auto;
    margin-right: auto;
}

/* FBA Save Style */
.fba-so {
    position: fixed;
    right: 20px;
    top: 20px;
    display: grid;
    z-index: 100;
}
.fba-so .MuiButton-root {
    padding: 20px;
    border-radius: 50%;
    margin-bottom: 10px;
}

@media (max-width: 767px) {
    .manual-table-bottom-btn.MuiGrid-item {
        margin-bottom: 1rem;
    }
    .manual-table-bottom-btn .MuiButton-root {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
}