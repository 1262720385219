/* Style for form in Modal */
.custom-form .MuiFormControl-root {
    display: block;
    margin-bottom: 20px;
}
.modal-submit-btn.MuiButton-root {
    font-weight: bold;
}
.modal-cancel-btn {
    float: right;
}
.modal-body {
    top: 50%;
    left: 50%;
    width: 30vw;
    position: absolute;
    background-color: white;
    border: 2px solid #000;
    box-shadow: 3px 3px 3px black;
    padding: 20px;
    border-radius: 10px;
    transform: translate(-50%, -50%);
    scrollbar-width: thin;
}
.modal-body::-webkit-scrollbar {
    width: 10px;
}
.modal-body::-webkit-scrollbar-track {
    background-color: lightgray;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}
.modal-body::-webkit-scrollbar-thumb {
    border-radius: 10px;
}

/* Style for update stock modal */
.stock-title {
    text-align: center !important;
    margin-bottom: 30px;
}
/* Style for export database */
.modal-body.export-db {
    width: 60vw;
    max-height: 70vh;
}
.export-db .product-list {
    overflow-x: hidden;
    scrollbar-width: thin;
    height: 32vh;
    padding-top: 5px;
    margin-bottom: 20px;
}
.export-db .full-width .MuiOutlinedInput-root {
    width: 100%;
}
.export-db .MuiButton-root {
    font-size: 0.8rem;
}
.export-db .remove-product-btn {
    margin-right: 2px;
}
@media (max-width: 600px) {
    .export-db .MuiButton-root {
        width: 100%;
        margin-bottom: 10px;
    }
}

/* Style for JWT error modal */
.jwt-title {
    text-align: center !important;
}
.jwt-text {
    text-align: center;
}
.MuiButtonBase-root.jwt-btn {
    display: block;
    margin: 1.5rem auto 1rem;
    font-weight: bold;
}

/* Style for half input field in modal */
.form-left-field .MuiInputBase-root {
    width: 100%;
}
.form-right-field .MuiInputBase-root {
    width: 100%;
}

@media (max-width: 479px) {
    .modal-body {
        width: 60vw;
    }
}

@media (min-width: 480px) and (max-width: 767px) {
    .modal-body {
        width: 50vw;
    }
}