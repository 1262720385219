/* Style for form layout */
.add-form {
    padding: 1rem 3rem 3rem;
    border-radius: 5px;
}

/* Style for submit form button */
.add-new-btn.MuiButton-root {
    min-width: 120px;
    margin-top: 50px;
    font-weight: bold;
}
.add-cancel-btn.MuiButton-root {
    min-width: 120px;
    margin-top: 50px;
    float: right;
    font-weight: bold;
}

/* Style for edit button in detail page */
.edit-btn.MuiButton-root {
    font-weight: bold;
    margin-top: 1.5rem;
    float: right;
}
.edit-btn.left-margin.MuiButton-root {
    margin-left: 10px;
}
.edit-btn .MuiButton-label {
    display: flex;
}
.print-btn.MuiButton-root {
    margin-right: 10px;
}

/* Stye for input field */
.MuiOutlinedInput-root legend span {
    margin-right: 4px;
}
.form-left-field {
    width: 97%;
    float: left;
    margin-bottom: 20px !important;
}
.form-right-field {
    width: 100%;
    float: right;
    margin-bottom: 20px !important;
}
.quarter-field {
    width: 97%;
    margin-bottom: 20px !important;
}
.quarter-field.quarter-right {
    float: right;
    margin-bottom: 20px !important;
}
.left.MuiFormControl-root.switch-layout {
    width: 97%;
}
.right.MuiFormControl-root.switch-layout {
    width: 99%;
    float: right;
}

/* Required Text Styles */
.text-required {
    margin-top: -15px;
    margin-bottom: 20px;
    text-align: left;
    font-size: 0.85em;
}
.text-required-half, .text-required.quarter {
    margin-top: -17px;
    float: left;
    margin-bottom: 1.2rem;
    font-size: 0.85em;
}
.text-required.use-spacing,
.text-required-half.use-spacing {
    margin-top: 5px;
    margin-bottom: 5px;
}
.text-required-half.half-right {
    margin-left: 5%;
    margin-bottom: 1.2rem;
}
.text-required.quarter {
    margin-left: 10px;
}
.text-required-no-margin {
    margin-top: 5px;
    font-size: 0.85em;
}

/* Style for sub-header text */
.form-subheader {
    margin-top: 10px;
    margin-bottom: 30px;
}

/* Style for input price */
.price-field {
    padding: 17px 13px 14px;
    font-size: 1rem;
    color: #592c0d;
    border-radius: 3px;
    border: solid 1px rgba(0, 0, 0, 0.23);
    width: -webkit-fill-available;
    width: -moz-available;
}
.price-field:hover {
    border-color: #592c0d;
}
.price-label {
    font-size: 0.75rem;
    color: black;
    background-color: white;
    margin-top: -10px;
    position: absolute;
    margin-left: 8px;
    padding: 0 6px;
    z-index: 10;
}
.restock-price.right .MuiInputBase-input {
    text-align: right;
}
.restock.manual-table .no-border .footer {
    padding-top: 15px;
    padding-bottom: 15px;
}

/* Disabled Style */
.disabled-field {
    background: none !important;
}
.disabled-field [class*="control"] {
    cursor: default;
    pointer-events: none;
}
.disabled-field:hover, .disabled-field [class*="control"]:hover {
    border-color:rgba(0, 0, 0, 0.22) !important;
}
.disabled-field [class*="multiValue"] svg,
.disabled-field [class*="chipContainer"] .MuiChip-deleteIcon {
    display: none;
}
.disabled-wrapper:hover {
    border-color:rgba(0, 0, 0, 0.22) !important;
}
.disabled-text-input {
    cursor: default;
    pointer-events: none;
}
.disabled-text-input .MuiInputBase-input {
    color: rgba(0, 0, 0, 0.4);
}

/* Style for selection field */
.selection-field [class*="control"] {
    padding: 9px 1px;
}
.selection-field [class*="menu"] {
    z-index: 120;
}
.selection-label {
    font-size: 0.78rem;
    margin-top: -10px;
    position: absolute;
    margin-left: 15px;
    padding: 0 6px;
    left: 0;
    z-index: 10;
}
.type-payment .selection-label {
    margin-left: 10px;
}
.selection-required {
    margin-top: -15px;
    margin-bottom: 20px;
}
.disabled-selection {
    pointer-events: none;
}

/* Style for image uploader */
.browse-btn {
    width: 25%;
    display: inline-block;
    padding: 10px 0;
    text-align: center;
    cursor: pointer;
    border: solid 1px darkgray;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}
.img-name {
    width: 62%;
    display: inline-grid;
    padding: 10px 5px;
    border: solid 1px darkgray;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}
.image-info {
    padding-left: 10px;
}
.delete-img-btn {
    position: relative;
}
.delete-img-btn .MuiSvgIcon-root {
    position: absolute;
    top: 5px;
    right: 5px;
    color: #F0F0F0;
}
.can-delete {
    display: initial !important;
    cursor: pointer;
}
.cannot-delete {
    display: none !important;
    cursor: default;
}

/* Style for switch / toggle form */
.MuiFormControl-root.switch-layout {
    border: solid 1px;
    border-color: rgba(0, 0, 0, 0.28);
    border-radius: 5px;
    padding: 8px 0;
    width: 100%;
}
.MuiFormControl-root.switch-layout:hover {
    border-color: black;
}
.MuiFormLabel-root.legend-switch {
    margin-left: 10px;
    font-size: 0.8em;
}
.MuiFormControlLabel-root.fcl-switch {
    padding-left: 10px;
}
.MuiFormControl-root.no-label .MuiFormControlLabel-root.fcl-switch {
    margin: 2px 0;
}

/* Style for input image */
.full-input-image .image-info {
    width: 45%;
    margin-left: auto;
    margin-right: auto;
}
.half-input-image .image-info {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
}
.input-img-terms {
    margin-top: 0;
    margin-bottom: 20px;
}

/* Style for manual search bar */
.search-bar.MuiFormControl-root {
    width: 100%;
}
.search-bar .MuiOutlinedInput-root {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
/* .search-bar [class*="control"] {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.search-bar [class*="IndicatorsContainer"] {
    display: none;
} */
.search-btn.MuiButton-root {
    width: 100%;
    padding: 15px 14px;
    /* padding: 14px; */
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    box-shadow: none;
    border: solid 1px rgba(0, 0, 0, 0.22);
}
.search-btn.MuiButton-root:hover {
    box-shadow: none;
}

.see-detail-btn.MuiButton-root {
    width: 80%;
    display: block;
    margin-left: auto;
    margin-right: 10%;
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-weight: bold;
}
.opname-btn.MuiButton-root {
    font-weight: bold;
    padding: 15px 14px;
}

/* Style for remove product button */
.remove-product-btn {
    display: block;
    margin-left: auto;
    margin-top: 14px;
    padding: 5px 10px;
    font-size: 1rem;
    border-radius: 50%;
    border-style: none;
    cursor: pointer;
    box-shadow: 
        0px 3px 1px -2px rgba(0,0,0,0.2),
        0px 2px 2px 0px rgba(0,0,0,0.14),
        0px 1px 5px 0px rgba(0,0,0,0.12);
}

/* Style for sell-warehouse subtotal section */
.subtotal-info {
    margin: 0;
    padding: 14px 0;
    text-align: right;
}

/* Manual Search Bar Style */
.manual-search.MuiFormControl-root {
    margin-top: 1.5rem;
}
.manual-search .MuiOutlinedInput-input {
    padding: 10px;
}

/* Smaller Textfield Font */
.small-font .MuiInputBase-root .MuiInputBase-input {
    font-size: 0.85rem;
}

/* Excel_Com Style */
.excel-com-btn.MuiButton-root {
    float: right;
    margin-top: 25px;
}

/* Generate Pin Style */
.generate-pin-notes {
    margin-bottom: -10px;
}

/* Copy Clipboard Result Area */
.MuiFormControl-root.copy-clipboard-area {
    width: 75%;
}
.copy-clipboard-area .MuiOutlinedInput-root {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.copy-clipboard-area .MuiInputBase-inputMultiline {
    height: 4.5rem;
    line-height: 1.3rem;
}
.MuiButton-root.generate-btn {
    font-family: 'Verdana';
    font-weight: bold;
    width: 25%;
    padding: 16px 14px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    box-shadow: none;
}
.MuiButton-root.generate-btn.copy {
    padding: 43px 14px;
}
.MuiButton-root.generate-btn:hover {
    box-shadow: none;
}
.generate-result {
    width: 74.5%;
    display: inline-block;
    border: solid 1px rgba(0, 0, 0, 0.28);
    padding: 18.5px 14px;
}
.generate-result .link-result {
    width: 100%;
    display: block;
    box-sizing: content-box;
}

/* Inline Checkbox Style */
.MuiFormGroup-root.cb-inline {
    display: inline;
    margin-left: 10px;
}

@media (max-width: 359px) {
    .form-subheader {
        font-size: 1rem;
    }
    .img-name {
        width: 60%;
        font-size: 0.8rem;
    }
    .image-info p {
        font-size: 0.8rem !important;
    }
    .browse-btn, .MuiOutlinedInput-root .MuiInputBase-input,
    .MuiFormControl-root.switch-layout span {
        font-size: 0.8rem;
    }
}

@media (max-width: 479px) {
    .add-form {
        padding: 1rem 1.5rem 3rem;
    }
    .add-form h1 {
        text-align: center;
        margin-bottom: 2rem !important;
    }
    .full-input-image .image-info,
    .half-input-image .image-info {
        width: 100%;
        padding: 0;
    }
    .browse-btn {
        width: 30%;
    }
    .form-right-field, .form-left-field {
        width: 100%;
    }
    .add-form h1 {
        text-align: center;
        margin-bottom: 2rem !important;
    }
    .browse-btn {
        width: 31%;
    }
    .edit-btn.MuiButton-root {
        float: none;
        display: block;
        margin: -5px auto 2rem !important;
        min-width: 100%;
    }
    .responsive-submit-btn.MuiButton-root {
        float: left !important;
        margin-right: 0 !important;
    }
    .add-new-btn.MuiButton-root, .add-cancel-btn.MuiButton-root {
        min-width: auto;
    }
    .add-new-btn.MuiButton-root, .add-cancel-btn.MuiButton-root {
        min-width: 100px;
    }
    .add-new-btn.MuiButton-root.generate {
        width: 100%;
        margin-bottom: 15px;
    }
    .MuiFormControl-root.copy-clipboard-area {
        width: 100%;
    }
    .copy-clipboard-area .MuiOutlinedInput-root {
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
    }
    .MuiButton-root.generate-btn.copy {
        width: 100%;
        padding: 15px;
        border-radius: 3px;
    }
    .preview-layout.MuiGrid-item {
        max-width: 100%;
    }
}

@media (max-width: 599px) {
    .so-btn-container .MuiGrid-item {
        display: block;
    }
    .so-btn-container .MuiGrid-item .MuiButton-root {
        width: 100%;
        margin: 0 0 20px;
        margin-right: 0 !important;
    }
    .left.MuiFormControl-root.switch-layout,
    .right.MuiFormControl-root.switch-layout {
        width: 100%;
    }
    .quarter-field.quarter-right {
        width: 100%;
    }
    .generate-pin-notes {
        text-align: center;
    }
    .print-btn.MuiButton-root {
        margin-right: 0;
    }
}

@media (min-width: 480px) and (max-width: 767px) {
    .add-form {
        padding: 1rem 2rem 3rem;
    }
    .add-form h1 {
        text-align: center;
        margin-bottom: 2rem !important;
    }
    .full-input-image .image-info {
        width: 80%;
    }
    .browse-btn {
        width: 30%;
    }
    .form-right-field, .form-left-field {
        width: 100%;
    }
    .edit-btn.MuiButton-root {
        float: none;
        display: block;
        margin: -5px auto 2rem !important;
        min-width: 100%;
    }
    .preview-layout.MuiGrid-item {
        max-width: 100%;
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .full-input-image .image-info {
        width: 80%;
    }
    .browse-btn {
        width: 30%;
    }
    .form-right-field, .form-left-field {
        width: 100%;
    }
    .edit-btn.MuiButton-root {
        float: none;
        display: block;
        margin: -5px auto 2rem !important;
        min-width: 100%;
    }
}

@media (max-width: 959px) {
    .preview-layout.MuiGrid-item {
        max-width: 100%;
    }
    .excel-com-btn.MuiButton-root {
        width: 100%;
        margin-top: -20px;
        margin-bottom: 1.5rem;
    }
}

@media (min-width: 960px) and (max-width: 1023px) {
    .form-left-field {
        width: 97%;
    }
    .edit-btn.MuiButton-root {
        float: right;
        min-width: auto;
        margin-top: 1.5rem !important;
    }
}

@media (min-width: 1024px) and (max-width: 1280px) {
    .full-input-image .image-info {
        width: 80%;
    }
    .browse-btn {
        width: 30%;
    }
    .img-name {
        width: 60%;
    }
}

@media (min-width: 1921px) {
    .add-form {
        max-width: 800px;
        margin-left: auto;
        margin-right: auto;
    }
}