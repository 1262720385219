/* Dashboard content style */
.content {
    min-height: 100vh;
    margin-left: 300px;
}
.grid-container {
    padding: 50px;
}

/* Burger button style */
.responsive-header h2 {
    display: none;
}
.responsive-header img {
    display: none;
}
.menu-icon-btn {
    display: none;
}

/* Download & Export button style */
.download-btn.MuiButton-root {
    position: absolute;
    top: 60px;
    right: 50px;
}
.download-btn.table.MuiButton-root {
    top: 50px;
}
.download-btn.with-branch.MuiButton-root {
    top: 135px;
    right: 57px;
}
.export-btn {
    margin-left: auto;
}

/* Income statement card style */
.income-state-card .MuiGrid-container {
    border-radius: 5px;
    height: 100%;
}
.income-state-card .MuiGrid-grid-xs-1 {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}
.income-state-card .MuiGrid-grid-xs-11 {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}
.income-state-card .income-card-content {
    padding-left: 10px;
    padding-right: 10px;
}

/* Card Styles */
.card-layout {
    width: 100%;
}
.card-header {
    text-align: center;
    padding: 10px !important;
    min-height: 4rem;
}
.card-header span {
    font-weight: bold;
}
.card-content {
    text-align: center;
    font-size: 1.2rem;
    padding: 0 !important;
}

@media (max-width: 479px) {
    .content {
        margin-left: 0;
    }
    .grid-container {
        padding: 30px 30px;
    }
    .responsive-header img, .responsive-header h2 {
        display: inherit;
        width: 100%;
        padding: 10px;
    }
    .responsive-header h2 {
        margin-left: 30px;
    }
    .menu-icon-btn.MuiGrid-item {
        display: flex;
        align-items: center;
    }
    .menu-icon-btn .MuiButton-root {
        display: inherit;
        margin-left: auto;
        margin-right: 30px;
        color: white;
        border-color: white;
    }
    .download-btn.MuiButton-root {
        width: calc(100vw - 72px);
        top: 35px;
    }
    .download-btn.table.MuiButton-root {
        width: calc(100vw - 60px);
        top: 25px;
        right: 30px;
    }
    .download-btn.with-branch.MuiButton-root {
        width: calc(100vw - 60px);
        top: 101px;
        right: 38px;
    }
    .export-btn {
        width: 100%;
        margin-left: 0;
    }
}

@media (min-width: 480px) and (max-width: 767px) {
    .content {
        margin-left: 0;
    }
    .grid-container {
        padding: 3rem;
    }
    .responsive-header img, .responsive-header h2 {
        display: inherit;
        width: 100%;
        padding: 10px;
    }
    .responsive-header h2 {
        margin-left: 30px;
    }
    .menu-icon-btn.MuiGrid-item {
        display: flex;
        align-items: center;
    }
    .menu-icon-btn .MuiButton-root {
        display: inherit;
        margin-left: auto;
        margin-right: 30px;
        color: white;
        border-color: white;
    }
    .download-btn.MuiButton-root {
        width: calc(100vw - 5.5rem);
        top: 50px;
    }
    .download-btn.table.MuiButton-root {
        width: calc(100vw - 6rem);
        top: 40px;
    }
    .download-btn.with-branch.MuiButton-root {
        top: 119px;
    }
    .export-btn {
        width: 100%;
        margin-left: 0;
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .content {
        margin-left: 200px;
    }
    .grid-container {
        padding: 35px;
    }
    .download-btn.MuiButton-root {
        top: 45px;
    }
    .download-btn.table.MuiButton-root {
        top: 35px;
        right: 35px;
    }
    .download-btn.with-branch.MuiButton-root {
        top: 119px;
        right: 45px;
    }
}