/* Make item full width */
.full-width {
    width: 100%;
}

/* Style to hide element */
.hide {
    display: none !important;
}

/* Style for margin */
.add-margin-top {
    margin-top: 20px !important;
}
.add-margin-bottom {
    margin-bottom: 20px !important;
}
.no-margin-bottom {
    margin-bottom: 0;
}

/* Float Style */
.float-right {
    float: right;
}

/* Input Pin Style */
.pin-input .MuiInputBase-root {
    width: 100%;
}

/* Page Not Found Style */
.page-not-found h1 {
    text-align: center;
    font-size: 5rem;
    margin: 1rem 0 0;
}
.page-not-found h2 {
    text-align: center;
    margin-top: 0;
}
.page-not-found .MuiButton-contained {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1.5rem;
}

/* Text Style */
.text-bold {
    font-weight: bold;
}