.filter-form {
    background-color: white;
    padding: 20px 15px;
    border-radius: 5px;
    margin: 0 !important;
}

.button-wrap {
    margin-left: auto;
}

.filter-submit-btn.MuiButton-root {
    font-weight: bold;
    margin-right: 10px;
}

.filter-selection.MuiGrid-item {
    position: relative;
}
.filter-selection .selection-label {
    margin-left: 10px;
}

.selected-filter {
    text-align: right;
    margin: 7px;
}

@media (max-width: 767px) {
    .with-download .filter-container.MuiGrid-container {
        margin-top: 50px;
    }
}