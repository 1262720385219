/* Color Variables */
:root {
    /* simple */
    --main-dark-green: #005c43;
    --sub-dark-green: #007f60;
    --main-green: #57ab4e;
    --main-light-green: #bfd730;
    --main-orange: #ea8c3a;
    --main-yellow: #ffe73f;
}

/* Main Color */
.login-container,
.sidebar,
.sidebar .MuiList-subheader .MuiListSubheader-root,
/* .manual-table .even-cell td, */
.filter-submit-btn.MuiButton-root,
.MuiGrid-root.responsive-header {
    background-color: var(--main-dark-green);
}

/* Main Color with Black Text */
.add-btn-table.MuiButton-root,
.MuiButton-root.MuiButton-contained,
.browse-btn,
.osm-browse-btn,
.manual-table th,
.MuiToolbar-regular,
.download-btn.MuiButton-root {
    background-color: var(--sub-dark-green);
    color: white;
}

/* Export DB 'Download ALl' Button Color */
.download-all.MuiButton-root {
    background-color: var(--main-green);
}
.download-all.MuiButton-root:hover {
    background-color: #578f51 !important;
}

/* Hover Color */
.MuiButton-root.MuiButton-contained:hover,
.add-btn-table.MuiButton-root:hover {
    background-color: var(--main-dark-green);
}
.MuiListItem-button.sidebar-item:hover,
.MuiListItem-button.sidebar-subitem:hover,
.active-link .sidebar-item,
.active-link .sidebar-subitem {
    background-color: var(--sub-dark-green);
}
.remove-product-btn:hover {
    background-color: rgb(230, 230, 230);
}

.colored-card {
    background-color: var(--sub-dark-green);
}

/* White Color */
#login-form,
.add-form,
.selection-label,
.MuiTableFooter-root .MuiTableCell-footer .MuiToolbar-gutters,
[class*="MTableToolbar-searchField"] .MuiInput-underline,
[class*="jss"].MuiTextField-root .MuiInputBase-root.MuiInput-root.MuiInput-underline,
.income-state-card .MuiGrid-container {
    background-color: white;
}

/* Black Color */
.MuiListItem-button.sidebar-item,
.MuiListItem-button.sidebar-subitem,
.MuiListItemIcon-root.sidebar-icon,
.material-table .MuiToolbar-root .MuiTypography-h6 {
    color: white;
}

.selection-field [class*="control"] [class*="ValueContainer"] [class*="singleValue"],
.disabled-field [class*="control"] [class*="ValueContainer"] [class*="singleValue"] {
    /* color: rgba(0, 0, 0, 0.4); */
    color: black;
}
.card-content,
.income-state-card .income-card-content h2 {
    color: rgb(100, 100, 100);
}

/* Checkbox Color */
.MuiCheckbox-colorSecondary.Mui-checked {
    color: var(--sub-dark-green) !important;
}
/* Textfield Color */
.MuiFormControl-root .MuiInputLabel-shrink,
.MuiFormControl-root .MuiInputBase-root.Mui-disabled {
    color: black !important;
}

/* Manual Table Color */
.manual-table {
    border-collapse: collapse;
}
/* .manual-table, */
.manual-table th,
.manual-table tr {
    border: solid 2px var(--sub-dark-green);
}
.transaction-table .manual-table .no-border {
    border: none;
}
/* .manual-table.transaction .footer,
.manual-table.transaction .footer-result {
    background-color: rgb(190, 190, 190);
} */
.card-header {
    background-color: var(--sub-dark-green);
    color: white;
}

/* Text Color */
h1 {
    color: var(--main-dark-green);
}

/* Error Color */
.text-required,
.text-required-half,
.text-required-no-margin,
.selection-required {
    color: red;
}
.error-style .MuiOutlinedInput-notchedOutline,
.error-style .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: red !important;
}
.MuiButton-root.MuiButton-containedSecondary {
    background-color: #f50057;
}
.MuiButton-root.MuiButton-containedSecondary:hover {
    background-color: #C51162;
}

/* Text Color */
.text-red {
    color: rgb(200, 70, 70);
}
.text-green {
    color: var(--main-green);
}

/* Status Color */
.status-done {
    background-color: var(--main-dark-green);
    color: white;
}
.status-validated {
    background-color: var(--main-green);
    color: white;
}
.status-approved {
    background-color: var(--main-light-green);
    color: white;
}
.status-declined {
    background-color: rgb(200, 70, 70);
    color: white;
}
.status-pending {
    background-color: var(--main-orange);
    color: white;
}

/* FAB Color */
.MuiSpeedDial-root .MuiFab-primary {
    background-color: var(--main-green);
}
.MuiSpeedDial-root .MuiFab-primary:hover {
    background-color: var(--main-green);
}

/* Outlined Button Color */
.MuiButton-root.MuiButton-outlined {
    border-color: var(--sub-dark-green);
    border-width: 2px;
}

/* Snackbar Color */
.MuiAlert-filledSuccess{
    background-color: var(--main-green) !important;
}

.content {
    background-color: #F0F0F0;
}
.Component-horizontalScrollContainer-12::-webkit-scrollbar-track {
    /* [CHROME] color of the tracking area */
    background: #F0F0F0;
}